//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { dayjs, VISIBLE_FORMAT } from "@/utils/dates";
import HistoryTable from "@/components/Billing/HistoryTable.vue";
import BillingPromocodeNotice from "@/components/Billing/PromocodeNotice";
const {
  CQ_tarif
} = require("@/components/CarrotQuest/CarrotQuest.js");
export default {
  components: {
    HistoryTable,
    BillingPromocodeNotice
  },
  meta: {
    title: "Тариф и оплата"
  },
  data() {
    return {
      billingState: null,
      currentOrganization: null,
      limits: null,
      plan_id: 0
    };
  },
  beforeRouteLeave(to, from, next) {
    // //console.log('Plan.129: beforeRouteLeave()')
    // //console.log('Plan.129: beforeRouteLeave(): sessionStorage.getItem(promocode.type)=', window.sessionStorage.getItem('promocode.type'))

    if (window.sessionStorage.getItem("promocode.type") == "china"
    // && !to.path.match(/profile/)
    ) {
      //console.log('Plan.135: beforeRouteLeave(): removeItem(promocode.*)')

      window.sessionStorage.removeItem("promocode:code");
      window.sessionStorage.removeItem("promocode:show");
      window.sessionStorage.removeItem("promocode.type");
      window.sessionStorage.removeItem("promocode.class");
      window.sessionStorage.removeItem("promocode.reason");
    }

    //console.log('Plan.129: beforeRouteLeave(): to=', to)
    //console.log('Plan.129: beforeRouteLeave(): from=', from)

    //console.log('Plan.151: -> CQ_tarif()')
    CQ_tarif(this.$api, this.user);
    next();
  },
  async mounted() {
    var _this$currentSubscrip;
    await this.load();
    if (this.$route.params.plan) {
      const isSamePlan = this.currentSubscriptions.some(i => i.plan.id === this.$route.params.plan.id);
      if (isSamePlan) {
        this.onProlong(this.$route.params.period);
      } else {
        this.onChange(this.$route.params.plan.id, this.$route.params.period);
      }
    }

    //console.log('255: this.currentSubscriptions[0]=',this.currentSubscriptions[0])
    this.plan_id = (_this$currentSubscrip = this.currentSubscriptions) === null || _this$currentSubscrip === void 0 || (_this$currentSubscrip = _this$currentSubscrip[0].plan) === null || _this$currentSubscrip === void 0 ? void 0 : _this$currentSubscrip.id;
  },
  methods: {
    async load() {
      /* //china : мб это ChinaCode
      const promocodeAllowed =
          !!window.sessionStorage.getItem("promocode:show");
      const promocode =
          promocodeAllowed &&
          window.sessionStorage.getItem("promocode:code");
      //console.log('Plan.152: promocode=', promocode)
      */

      //                                 V - определено в Promo.vue
      if (window.sessionStorage.getItem("promocode.type") == "china") {
        try {
          const chinaCode = window.sessionStorage.getItem("promocode:code");
          const r = await this.$store.dispatch("billing/trySetChinaPlan", {
            chinaCode
          });
          //console.log('Plan.183: r=', r)
          if (r) {
            window.sessionStorage.setItem("promocode.type", "china");
            window.sessionStorage.setItem("promocode.class", r.class);
            window.sessionStorage.setItem("promocode.reason", r.reason);
            //console.log('Plan.188: setItem()')
          }
        } catch (e) {
          var _e$response;
          if (((_e$response = e.response) === null || _e$response === void 0 ? void 0 : _e$response.status) === 404) {
            // это не СhinaCode
            window.sessionStorage.removeItem("promocode.type");
            window.sessionStorage.removeItem("promocode.class");
            window.sessionStorage.removeItem("promocode.reason");
            //console.log('Plan.195: r=404: !chinaCode')
          }
        }
      }

      return Promise.all([this.$store.dispatch("user/getUser", {
        force: true
      }), this.loadBillingState(), this.loadCurrentOrganization(), this.loadLimits()]);
    },
    async loadBillingState() {
      this.billingState = await this.$store.dispatch("billing/getState");
    },
    async loadCurrentOrganization() {
      this.currentOrganization = await this.$store.dispatch("billing/getCurrentOrganization").catch(e => {
        var _e$response2;
        return ((_e$response2 = e.response) === null || _e$response2 === void 0 ? void 0 : _e$response2.status) === 404 ? null : Promise.reject(e);
      });
    },
    async onChangeCurrentOrganization() {
      const organization = await this.$modal.process(() => import("@/components/Billing/ModalCustomerOrganization"), {
        data: this.currentOrganization || {}
      });
      this.currentOrganization = await this.$store.dispatch("billing/updateCurrentOrganization", organization);
    },
    async loadLimits() {
      var _repricerLimit$data, _repricerLimit$data2;
      let limits = await this.$store.dispatch("billing/getLimits");
      let rrcLimit = await this.$store.dispatch("priceVar/getRrcLimit", {
        fake: 1
      });
      let repricerLimit = await this.$store.dispatch("repricer/getRepricerLimit");
      /* //!DBG: на время отладки get7bot
      let repricerLimit = {
          data: {
              sku_quota: 99999,
              sku_count: 77777,
          }
      }
      */

      //console.log('Plan.326: repricerLimit=',repricerLimit)

      this.limits = {
        ...limits,
        rrcLimit: rrcLimit.limit,
        rrcCount: rrcLimit.count,
        repricerLimit: repricerLimit !== null && repricerLimit !== void 0 && (_repricerLimit$data = repricerLimit.data) !== null && _repricerLimit$data !== void 0 && _repricerLimit$data.sku_quota ? repricerLimit.data.sku_quota : 0,
        repricerCount: repricerLimit !== null && repricerLimit !== void 0 && (_repricerLimit$data2 = repricerLimit.data) !== null && _repricerLimit$data2 !== void 0 && _repricerLimit$data2.sku_count ? repricerLimit.data.sku_count : 0
      };
    },
    async onChange(preferredPlanId, preferredPeriod) {
      var _this$currentSubscrip2;
      if (this.user.parent) {
        return;
      }
      await this.$modal.process(() => import("@/components/Billing/ModalPayment"), {
        type: (_this$currentSubscrip2 = this.currentSubscriptions) !== null && _this$currentSubscrip2 !== void 0 && _this$currentSubscrip2.length ? "change" : "first",
        preferredPlanId,
        preferredPeriod
      });
      await this.load();
      /*
      // this.$refs.modalPayment.open({
      //     type: "change",
      // });
      */
    },

    async onProlong(preferredPeriod) {
      if (this.user.parent) {
        return;
      }
      await this.$modal.process(() => import("@/components/Billing/ModalPayment"), {
        type: "prolong",
        preferredPeriod
      });
      await this.load();
    },
    //&:
    async onAddRepriceLimit() {
      if (this.user.parent) {
        return;
      }

      //console.log('390: this.user=', this.user)

      await this.$modal.process(() => import("@/components/Billing/ModalPayment"), {
        type: "addLimit",
        preferredPlanId: 30,
        subscriptionAddLimit: this.currentSubscriptions[0],
        user: this.user
      });
      await this.load();
    }
  },
  computed: {
    backmessage() {
      if ("error" == this.$route.query.back) {
        return "Возникла ошибка при выполнении оплаты. Повторите попытку позже.";
      } else {
        if ("success" == this.$route.query.back) {
          return "Оплата прошла успешно.";
        }
      }
      return "";
    },
    backms() {
      if ("error" == this.$route.query.back) {
        return true;
      }
      return false;
    },
    backms2() {
      if ("success" == this.$route.query.back) {
        return true;
      }
      return false;
    },
    promocodeType() {
      //china
      return window.sessionStorage.getItem("promocode.type");
    },
    currentSubscriptions() {
      return this.$store.state.billing.currentSubscriptions;
    },
    planName() {
      var _this$currentSubscrip3, _this$currentSubscrip4;
      //console.log('302: this.currentSubscriptions=',this.currentSubscriptions)

      if (((_this$currentSubscrip3 = this.currentSubscriptions) === null || _this$currentSubscrip3 === void 0 || (_this$currentSubscrip3 = _this$currentSubscrip3[0]) === null || _this$currentSubscrip3 === void 0 || (_this$currentSubscrip3 = _this$currentSubscrip3.plan) === null || _this$currentSubscrip3 === void 0 ? void 0 : _this$currentSubscrip3.name) === "Demo") {
        return "Demo (Бесплатное тестирование 24 часа)";
      }
      return ((_this$currentSubscrip4 = this.currentSubscriptions) === null || _this$currentSubscrip4 === void 0 || (_this$currentSubscrip4 = _this$currentSubscrip4[0]) === null || _this$currentSubscrip4 === void 0 || (_this$currentSubscrip4 = _this$currentSubscrip4.plan) === null || _this$currentSubscrip4 === void 0 ? void 0 : _this$currentSubscrip4.name) || "Free";
    },
    /*
    planId() {
        return this.currentSubscriptions?.[0]?.plan?.id;
    },
    */
    minExpirationDate() {
      const getMinExpiration = subscriptions => subscriptions.reduce((min, subscription) => {
        const date = dayjs(subscription.date_end);
        if (!min) {
          return date;
        }
        return date.isBefore(min) ? date : min;
      }, null);
      return getMinExpiration(this.billingState.currentSubscriptions);
    },
    expiration() {
      const minDate = this.minExpirationDate;
      return minDate ? minDate.format(VISIBLE_FORMAT) : "-";
    },
    isExpired() {
      if (!this.minExpirationDate) {
        return false;
      }
      return this.minExpirationDate.isBefore(dayjs());
    },
    user() {
      return this.$store.state.user.user;
    },
    ready() {
      return this.user && this.billingState && this.currentSubscriptions && this.limits;
    }
  }
};